import { Injectable, OnDestroy } from "@angular/core";
import { environment } from "src/environments/environment";
import io from "socket.io-client";
import { CredentialsService } from "../core/credentials.service";
import { Subject } from "rxjs";

export enum SocketEvents {
  WaitingRoom = "WAITING_ROOM",
  FollowUpRoom = "FOLLOW_UP_ROOM",
  RemoveFromWaitingRoom = "REMOVE_FROM_WAITING_ROOM",
  RemoveFromFollowUpRoom = "REMOVE_FROM_FOLLOW_UP_ROOM",
  Document = "DOCUMENT",
  Connect = "connect",
  Disconnect = "disconnect",
  AgentScheduleRoom = "AGENT_SCHEDULE_ROOM",
  AgentScheduledRoom = "AGENT_SCHEDULED_ROOM",
  AgentRescheduleRoom = "AGENT_RESCHEDULE_ROOM",
  RemoveFromAgentScheduleRoom = "REMOVE_FROM_AGENT_SCHEDULE_ROOM",
  RemoveFromAgentScheduledRoom = "REMOVE_FROM_AGENT_SCHEDULED_ROOM",
  RemoveFromAgentRescheduleRoom = "REMOVE_FROM_AGENT_RESCHEDULE_ROOM",
  DoctorSpecialistWaitingRoom = "DOCTOR_SPECIALIST_WAITING_ROOM",
  RemoveFromDoctorSpecialistWaitingRoom = "REMOVE_FROM_DOCTOR_SPECIALIST_WAITING_ROOM",
}

export type SocketEvent = MessageEvent & {
  operation: SocketEvents;
  consultation: any;
};

@Injectable({
  providedIn: "root",
})
export class SocketioService implements OnDestroy {
  // private socket: SocketIOClient.Socket;
  private socket: any;
  private connection$: Subject<SocketEvent>;

  constructor(private credentialsService: CredentialsService) {}

  ngOnDestroy(): void {
    this.disconnect();
  }

  connect(): Subject<SocketEvent> {
    if (!this.socket && !this.connection$) {
      this.connection$ = new Subject<SocketEvent>();
      this.socket = io(environment.socket_url, {
        query: {
          auth_token: this.credentialsService.credentials.token,
        },
        transports: ["websocket"],
        forceNew: true,
      });

      this.subscribeToAllEvents(this.connection$);
    }

    return this.connection$;
  }

  disconnect() {
    if (this.socket) {
      this.unsubscribeAllEvents();
      this.socket.disconnect();
      this.socket = null;
    }

    if (this.connection$) {
      this.connection$.complete();
      this.connection$ = null;
    }
  }

  emit(event: SocketEvents): void {
    if (this.socket) {
      this.socket.emit(event, JSON.stringify({}));
    }
  }

  private subscribeToEvent(
    event: SocketEvents,
    callback: (data: any) => void,
  ): void {
    if (this.socket) {
      this.socket.off(event);
      this.socket.on(event, callback);
    }
  }

  private subscribeToAllEvents(connection$: Subject<MessageEvent>): void {
    if (this.socket) {
      this.subscribeToEvent(SocketEvents.Connect, (data) => {});
      this.subscribeToEvent(SocketEvents.Disconnect, (data) => {});
      this.subscribeToEvent(SocketEvents.WaitingRoom, (data) => {
        console.log(SocketEvents.WaitingRoom, data);
        data.operation = SocketEvents.WaitingRoom;
        connection$.next(data);
      });

      this.subscribeToEvent(SocketEvents.FollowUpRoom, (data) => {
        console.log(SocketEvents.FollowUpRoom, data);
        data.operation = SocketEvents.FollowUpRoom;
        connection$.next(data);
      });
      this.subscribeToEvent(SocketEvents.Document, (data) => {
        data.operation = SocketEvents.Document;
        connection$.next(data);
      });
      this.subscribeToEvent(SocketEvents.RemoveFromWaitingRoom, (data) => {
        data.operation = SocketEvents.RemoveFromWaitingRoom;
        connection$.next(data);
      });
      this.subscribeToEvent(SocketEvents.RemoveFromFollowUpRoom, (data) => {
        data.operation = SocketEvents.RemoveFromFollowUpRoom;
        connection$.next(data);
      });
      this.subscribeToEvent(SocketEvents.AgentScheduleRoom, (data) => {
        console.log(SocketEvents.AgentScheduleRoom, data, "agent event");
        data.operation = SocketEvents.AgentScheduleRoom;
        connection$.next(data);
      });
      this.subscribeToEvent(SocketEvents.AgentScheduledRoom, (data) => {
        console.log(SocketEvents.AgentScheduledRoom, data, "agent event");
        data.operation = SocketEvents.AgentScheduledRoom;
        connection$.next(data);
      });
      this.subscribeToEvent(SocketEvents.AgentRescheduleRoom, (data) => {
        console.log(SocketEvents.AgentRescheduleRoom, data, "agent event");
        data.operation = SocketEvents.AgentRescheduleRoom;
        connection$.next(data);
      });
      this.subscribeToEvent(
        SocketEvents.DoctorSpecialistWaitingRoom,
        (data) => {
          console.log(
            SocketEvents.DoctorSpecialistWaitingRoom,
            data,
            "doc event",
          );
          data.operation = SocketEvents.DoctorSpecialistWaitingRoom;
          connection$.next(data);
        },
      );
      this.subscribeToEvent(
        SocketEvents.RemoveFromAgentScheduleRoom,
        (data) => {
          data.operation = SocketEvents.RemoveFromAgentScheduleRoom;
          connection$.next(data);
        },
      ); //
      this.subscribeToEvent(
        SocketEvents.RemoveFromAgentScheduledRoom,
        (data) => {
          data.operation = SocketEvents.RemoveFromAgentScheduledRoom;
          connection$.next(data);
        },
      );
      this.subscribeToEvent(
        SocketEvents.RemoveFromAgentRescheduleRoom,
        (data) => {
          data.operation = SocketEvents.RemoveFromAgentRescheduleRoom;
          connection$.next(data);
        },
      );
      this.subscribeToEvent(
        SocketEvents.RemoveFromDoctorSpecialistWaitingRoom,
        (data) => {
          data.operation = SocketEvents.RemoveFromDoctorSpecialistWaitingRoom;
          connection$.next(data);
        },
      );
    }
  }

  private unsubscribeAllEvents(): void {
    if (this.socket) {
      this.socket.off(SocketEvents.Connect);
      this.socket.off(SocketEvents.Disconnect);
      this.socket.off(SocketEvents.WaitingRoom);
      this.socket.off(SocketEvents.FollowUpRoom);
      this.socket.off(SocketEvents.Document);
      this.socket.off(SocketEvents.RemoveFromWaitingRoom);
      this.socket.off(SocketEvents.RemoveFromFollowUpRoom);
      ///
      this.socket.off(SocketEvents.AgentScheduleRoom);
      this.socket.off(SocketEvents.AgentScheduledRoom);
      this.socket.off(SocketEvents.AgentRescheduleRoom);
      this.socket.off(SocketEvents.DoctorSpecialistWaitingRoom);
      this.socket.off(SocketEvents.RemoveFromAgentScheduleRoom);
      this.socket.off(SocketEvents.RemoveFromAgentScheduledRoom);
      this.socket.off(SocketEvents.RemoveFromAgentRescheduleRoom);
      this.socket.off(SocketEvents.RemoveFromDoctorSpecialistWaitingRoom);
    }
  }
}
