import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpErrorResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";

import { environment } from "src/environments/environment";
import { CredentialsService } from "./core/credentials.service";
import { retry, catchError } from "rxjs/operators";
import { Observable, throwError } from "rxjs";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    public credentialsService: CredentialsService,
    public translateService: TranslateService,
    private router: Router,
  ) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const token = this.credentialsService.credentials
      ? this.credentialsService.credentials
      : "";
    if (!token) {
      req = req.clone({
        url: environment.base_url + req.url,
        headers: req.headers.set(
          "Accept-Language",
          this.translateService.currentLang,
        ),
      });
    } else {
      req = req.clone({
        url: environment.base_url + req.url,
        setHeaders: {
          Accept: "application/json",
          Authorization: `Bearer ${this.credentialsService.credentials.token}`,
        },
        headers: req.headers.set(
          "Accept-Language",
          this.translateService.currentLang,
        ),
      });
    }
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status == 401) {
          this.credentialsService.setCredentials();
          this.router.navigate(["/login"]);
        }
        return throwError(() => {
          throw error;
        });
      }),
    );
  }
}
