export const environment = {
    production: true,
    // base_url: "https://dev.api.sinapsis.holadoc.tk/v1",
    // socket_url: "https://dev.api.sinapsis.holadoc.tk",
    // base_url: "https://qa.api.sinapsis.holadoc.tk/v1",
    // socket_url: "https://qa.api.sinapsis.holadoc.tk",
    // base_url: "https://api.sinapsishealth.com/v1",
    // socket_url: "https://api.sinapsishealth.com",
    // base_url: "https://demo.api.sinapsis.holadoc.tk/v1",
    // socket_url: "https://demo.api.sinapsis.holadoc.tk",
    // base_url: "https://qa.api.poweredby.holadoc.tk/v1",
    // socket_url: "https://qa.api.poweredby.holadoc.tk/v1",
    base_url: 'https://dev.api.framework.holadoc.tk',
    socket_url: 'https://dev.api.framework.holadoc.tk',
    defaultLanguage: "es-ES",
    supportedLanguages: ["en-US", "es-ES"],
    userIdleTime: 600,
    logOutDuration: 1800,
    qbEnvirnoment: "production",
    organizationId: "63442b879172715afece9e04", //PROD ID
    //organizationId: "6151c649010f726c775c506e", //QA ID
};